<template>
  <div class="form-wrapper">
    <div class="action-button-wrapper">
      <button v-if="disabled" class="btn btn-primary editButton" @click="startEdit">Edit</button>
      <button v-if="!disabled" class="btn btn-primary saveButton" @click="save">Save</button>
    </div>

    <h2 class="card-title">Customer information</h2>
    <div class="card related-user-card">
      <el-form class="related-user-form" ref="related-user-form" v-if="checkIsAdmin()" :disabled="disabled" :model="form" label-width="200px">
        <div class="row">
          <el-form-item label="Principal Employee">
            <el-autocomplete
            class="inline-input addMemberInput"
            :fetch-suggestions="searchEmployee"
            v-model="form.principalEmployeeId"
            placeholder="Input Employee Name"
            :trigger-on-focus="false"
            ></el-autocomplete>
          </el-form-item>
        </div>
      </el-form>
      <el-form class="related-user-form" ref="related-user-form" v-if="!checkIsAdmin()" :disabled="true" :model="form" label-width="200px">
        <div class="row">
          <el-form-item label="Principal Employee">
            <el-autocomplete
            class="inline-input addMemberInput"
            :fetch-suggestions="searchEmployee"
            v-model="form.principalEmployeeId"
            placeholder="Input Employee Name"
            :trigger-on-focus="false"
            ></el-autocomplete>
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div class="card info-card">
      <el-form ref="employee-form" :disabled="disabled" :model="form" label-width="200px">
        <div class="row">
          <el-form-item label="Customer Name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="Company">
            <el-input v-model="form.company"></el-input>
          </el-form-item>
          <el-form-item label="Email Address">
            <el-input v-model="form.emailAddress"></el-input>
          </el-form-item>
          <el-form-item label="Phone Number">
            <el-input v-model="form.phoneNumber"></el-input>
          </el-form-item>
          <el-form-item label="Source">
            <el-input v-model="form.source"></el-input>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="Address" style="width: 75%;">
            <el-input v-model="form.address"></el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Hr from '@/lib/hr';
import Sales from '@/lib/sales';

export default {
  name: 'CustomerForm',
  props:{
    viewMode: Boolean,
    eventName: String,
  },
  mounted(){
    const isAdmin = this.checkIsAdmin();
    if(!isAdmin){
      this.form.principalEmployeeId = this.currentUser.employee_name;
    }
  },
  data(){
    let disabled = false;

    //Default form value
    let form = {
      name: '',
      company: '',
      address: '',
      emailAddress: '',
      phoneNumber: '',
      source: '',
      principalEmployeeId: '',
    };

    //If view mode is enabled, disable the form
    if(this.viewMode === true){
      disabled = true;
    };

    return {
      disabled,
      form,
    };
  },
  methods:{
    checkIsAdmin(){
      const currentUser = this.currentUser;
      if(currentUser){
        const isAdmin = Common.checkIsAdmin(currentUser);
        return isAdmin;
      }
      return false;
    },
    startEdit(){
      this.disabled = false;
    },
    async save(){
      this.$emit(this.eventName, this.form);
      this.disabled = true;
    },
    async searchEmployee(queryString, cb){
      const loginInfo = Common.getLoginInfo();
      const employees = await Hr.loadAllEmployees(this.apiUrl, loginInfo);
      const employeeSuggest = [];

      for(let i = 0; i < employees.length; i++){
        const employee = employees[i];
        const employeeSelection = {
          'value': employee.employee_name,
          'name': employee.employee_name
        };
        employeeSuggest.push(employeeSelection);
      }
      const results = queryString ? employeeSuggest.filter(this.createEmployeeFilter(queryString)) : employees;

      cb(results);
    },
    createEmployeeFilter(queryString) {
      return (employee) => {
        return (employee.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
  },
  watch: {
    'currentCustomer': function(){
      this.form = {
        name: this.currentCustomer.name,
        company: this.currentCustomer.company,
        address: this.currentCustomer.address,
        emailAddress: this.currentCustomer.email_address,
        phoneNumber: this.currentCustomer.phone_number,
        source: this.currentCustomer.source,
        principalEmployeeId: this.currentCustomer.employee_name,
      };
    }
  },
  computed: mapState({
    currentUser: state => state.currentUser,
    currentCustomer: state => state.sales.currentCustomer,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.related-user-card{
  padding: 30px;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  .related-user-form{
    margin-left: 15px;
    margin-top: 20px;
  }
}

.info-card{
  padding: 30px;
}

.card-title{
  margin-bottom: 30px;
}

.contact-info{
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;

  .info-card{
    padding-left: 30px;
  }
}

.action-button-wrapper{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;

  .btn{
    margin-right: 20px;
    &:last-child{
      margin: 0;
    }
  }
}
</style>
